import {
  QueryClient,
  QueryKey,
  useMutation as useReactMutation,
  UseMutationOptions as UseReactMutationOptions,
  UseMutationResult as UseReactMutationResult,
  useQuery as useReactQuery,
  UseQueryOptions as UseReactQueryOptions,
  UseQueryResult as UseReactQueryResult,
} from '@tanstack/react-query';
import Axios, { AxiosError, AxiosInstance, AxiosResponse, CancelTokenSource } from 'axios';
import { pick } from 'lodash';

import { getBaseUrl } from '@core/base-url';
import { showNotification } from '@shared/components/notification';
import { IndexResponse, ListResponsePagination } from '@shared/types/services';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';
import { getConfig } from '@shared/utils/window';

export const defaultPagination: ListResponsePagination = {
  count: 0,
  current_page: 1,
  from: 0,
  to: 0,
  links: {
    last: '',
    next: '',
  },
  per_page: 0,
  total: 0,
  total_pages: 0,
  last_page: 0,
};

export type SuccessResponse<T = any> = AxiosResponse<T>;

export interface FailureResponse<T = any> extends AxiosError<T> {
  errorMessages: string[];
}

export interface YieldResponse<T1 = any, T2 = any> {
  response?: SuccessResponse<T1>;
  error?: FailureResponse<T2>;
}

export type YieldSuccessResponse = (response: AxiosResponse<any>) => YieldResponse;

export type YieldFailureResponse = (error: AxiosError<any>) => YieldResponse;

export const yieldSuccessResponse: YieldSuccessResponse = (response) => {
  return { response } as YieldResponse;
};

export const yieldFailureResponse: YieldFailureResponse = (error) => {
  return { error } as YieldResponse;
};

export const getHTTPClient = (withApiPrefix = true): AxiosInstance => {
  const instance = Axios.create();
  instance.defaults.baseURL = `${getBaseUrl()}${withApiPrefix ? 'api/' : ''}`;

  instance.interceptors.request.use((config) => ({
    ...config,
    withCredentials: true,
    headers: {
      ...config.headers,
      ...(!!getConfig()?.coogan && { 'X-COOGAN': true }),
    },
  }));

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error.response;

      if (status === 401) {
        window.location.href = `${getBaseUrl()}logout${getQueriesAsSearch({ accountLevel: data?.accountLevel || '' })}`;
      }

      if (status === 412) {
        window.location.href = 'https://agents-society.com/terms';
      }

      if (status === -1) {
        if (error?.response?.config?.timeout) {
          return;
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const getExternalHTTPClient = (baseUrl: string): AxiosInstance => {
  const instance = Axios.create();
  instance.defaults.baseURL = baseUrl;

  return instance;
};
export const getCancelRequestSource = (): CancelTokenSource => Axios.CancelToken.source(); // TODO remove deprecated method after Axios update

export const getDefaultIndexResponse = <TD = unknown>(): IndexResponse<TD> => ({
  data: [],
  meta: {
    pagination: defaultPagination,
  },
});

// Use Mutation
export type UseMutationOptions<
  TVariables = unknown,
  TData = unknown,
  TError extends AxiosError = AxiosError,
  TContext = unknown
> = UseReactMutationOptions<TData, TError, TVariables, TContext>;

export const useMutation = <
  TVariables = unknown,
  TData = unknown,
  TError extends AxiosError = AxiosError,
  TContext = unknown
>(
  options: UseReactMutationOptions<TData, TError, TVariables, TContext>
): UseReactMutationResult<TData, TError, TVariables, TContext> =>
  useReactMutation<TData, TError, TVariables, TContext>(options);

// Use Query
export type UseQueryOptions<
  TData = unknown,
  TError extends AxiosError = AxiosError,
  TQueryFnData = TData
> = UseReactQueryOptions<TQueryFnData, TError, TData, any>;

export type QueryFactory<T = unknown> = (payload: any) => UseQueryOptions<T>;

export const useQuery = <
  TQK extends QueryKey = QueryKey,
  TData = unknown,
  TError extends AxiosError = AxiosError,
  TQueryFnData = TData
>(
  options: UseReactQueryOptions<TQueryFnData, TError, TData, TQK>
): UseReactQueryResult<TData, TError> => useReactQuery<TQueryFnData, TError, TData, TQK>(options);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      onError: () => showNotification(getDefaultError(), 'error'),

      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
      onError: () => showNotification(getDefaultError(), 'error'),
    },
  },
});

export const cancelQueries = (options: UseQueryOptions) => queryClient.cancelQueries(pick(options, ['queryKey']));

export type UpdateCacheCallback<T = unknown> = (cache: T) => T;

export const updateQueryCache = <TD = unknown>(queryKey: QueryKey, callback: UpdateCacheCallback<TD>) =>
  queryClient.setQueryData<TD>(queryKey, (cache) => (cache ? callback(cache) : cache));

export const clearIndexQuery = <TD extends ItemWithId = ItemWithId>(
  queryFactory: QueryFactory<IndexResponse<TD>>,
  params?: Query
) => {
  queryClient.setQueryData<IndexResponse<TD>>(queryFactory({ query: { ...params } }).queryKey, () =>
    getDefaultIndexResponse()
  );
};

export const updateIndexQueryItem = <TD extends ItemWithId = ItemWithId, TI extends Id = Id>(
  queryFactory: QueryFactory<IndexResponse<TD>>,
  id: TI,
  data: TD,
  params?: Query
) => {
  queryClient.setQueryData<IndexResponse<TD>>(queryFactory({ query: { ...params } }).queryKey, (indexResponse) => {
    if (indexResponse) {
      return {
        ...indexResponse,
        data: indexResponse.data.map((item) => (item.id === id ? data : item)),
      };
    }

    return indexResponse;
  });
};

export const deleteIndexQueryItem = <TD extends ItemWithId = ItemWithId, TI extends Id = Id>(
  queryFactory: QueryFactory<IndexResponse<TD>>,
  id: TI,
  params?: Query
) => {
  queryClient.setQueryData<IndexResponse<TD>>(queryFactory({ query: { ...params } }).queryKey, (indexResponse) => {
    if (indexResponse) {
      return {
        ...indexResponse,
        data: indexResponse.data.filter((item) => item.id !== id),
      };
    }

    return indexResponse;
  });
};
