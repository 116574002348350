import { createContext, FC, useContext, useMemo } from 'react';

import { CompanyStatus } from '@apis/companiesApi.types';
import { ContactStatus } from '@apis/contactsApi.types';
import { FeeStatus } from '@apis/feesApi.types';
import { RequirementStatus } from '@apis/requirementsApi.types';
import { TaskFrequency } from '@apis/tasksApi.types';
import { useConfig } from '@core/contexts/ConfigContext';
import { t } from '@core/i18n';
import { ShortWeekDay } from '@modules/insights/pages/viewings-old/components/viewings-drawer-old/components/viewings-task-edit/ViewingsTaskEdit.types';
import { GroupedOption } from '@shared/components/group-select-new';
import { ViewingOutcome } from '@shared/models/viewing/viewings';
import { mapStringRecord } from '@shared/utils/common';
import {
  filterOptions,
  groupOptionsByBuildingType,
  idArrayToNumberOptions,
  objectToNumberOptions,
  objectToOptions,
  sourceOptionsSelectOptions,
  stringObjectToNumberOptions,
  stringObjectToOptions,
} from '@shared/utils/options';

export type OptionsItem<T = Id> = { id: T; label: string; show?: boolean };

export type UseOptionResult = {
  acquisitionIntelStatusOptions: OptionsItem<RequirementStatus>[];
  advisoryStatusOptions: OptionsItem<number>[];
  advisoryTypeOptions: OptionsItem<number>[];
  archiveRadioOptions: OptionsItem[];
  availabilityMonthOptions: OptionsItem[];
  availableBuildingTypeGroupOptions: GroupedOption<string>[];
  availableMeasureOptions: OptionsItem[];
  availableSizeMetricOptions: OptionsItem<Metric>[];
  availableTaskTypeOptions: OptionsItem[];
  booleanOptions: OptionsItem<number>[];
  booleanSpecOptions: OptionsItem<string>[];
  booleanStringOptions: OptionsItem<string>[];
  buildingTypeOptions: OptionsItem[];
  buildStatusOptions: OptionsItem[];
  companyStatusOptions: OptionsItem<CompanyStatus>[];
  companyTypeOptions: OptionsItem<string>[];
  confirmedEventOptions: OptionsItem[];
  consentSourceOptions: OptionsItem[];
  contactStatusOptions: OptionsItem<ContactStatus>[];
  contactTitleOptions: OptionsItem[];
  contactTypeOptions: OptionsItem[];
  countryOptions: OptionsItem<string>[];
  creatableAdvisoryStatusOptions: OptionsItem[];
  creatableTaskTypeOptions: OptionsItem[];
  currencyOptions: OptionsItem[];
  devPipelineLikelihoodOptions: OptionsItem[];
  devPipelineFundingOptions: OptionsItem[];
  devPipelineStatusOptions: OptionsItem[];
  devPipelineTypeOptions: OptionsItem[];
  diaryTaskStatusOptions: OptionsItem[];
  discountReasonOptions: OptionsItem[];
  disposalConditionTypeOptions: OptionsItem[];
  disposalFittedSpaceOptions: OptionsItem[];
  disposalGradeOptions: OptionsItem[];
  disposalPublishedToOptions: OptionsItem[];
  disposalStatusOptions: OptionsItem[];
  disposalTermsOfEngagementOptions: OptionsItem[];
  epcBandOptions: OptionsItem[];
  eventNotificationOptions: OptionsItem[];
  feeCurrencyOptions: OptionsItem[];
  feesAdvisoryStages: OptionsItem<number>[];
  feesAllStagesOptions: OptionsItem<number>[];
  feesBasisOptions: OptionsItem[];
  feesDisposalLeaseStagesOptions: OptionsItem<number>[];
  feesPaymentStatusOptions: OptionsItem<number>[];
  feesRequirementLeaseStagesOptions: OptionsItem<number>[];
  feesSourceCrossSellExternalOptions: OptionsItem[];
  feesSourceCrossSellInternalOptions: OptionsItem[];
  feesSourceOptions: OptionsItem<number>[];
  feesSourceOwnActivityOptions: OptionsItem[];
  feesStatusOptions: OptionsItem<FeeStatus>[];
  feesTypeOptions: OptionsItem[];
  filterableRentMetricOptions: OptionsItem<Metric>[];
  fitoutConceptOptions: OptionsItem[];
  fitoutConditionOptions: OptionsItem[];
  floorUnitAvailabilityColumnOptions: OptionsItem[];
  includeInstructionOptions: OptionsItem[];
  investmentCurrencyOptions: OptionsItem<string>[];
  investmentCurrencySymbolOptions: OptionsItem<string>[];
  investmentGradeOptions: OptionsItem[];
  investmentMetricOptions: OptionsItem[];
  investmentProfileOptions: OptionsItem[];
  investmentStrategyOptions: OptionsItem[];
  investmentStructureOptions: OptionsItem[];
  investorRankingOptions: OptionsItem[];
  investorRegionOptions: OptionsItem[];
  investorTypeOptions: OptionsItem[];
  jointExclusiveInstructionOptions: OptionsItem[];
  kycCompletedOptions: OptionsItem[];
  leaseExpiryReasonOptions: OptionsItem[];
  leaseTypeOptions: OptionsItem[];
  marketingListOptions: OptionsItem[];
  matchDisposalsSortOptions: OptionsItem[];
  metricOptions: OptionsItem<Metric>[];
  nationalityOptions: OptionsItem[];
  officesOptions: OptionsItem<number>[];
  openEventOptions: OptionsItem[];
  ownershipTypeOptions: OptionsItem<number>[];
  planningStatusOptions: OptionsItem[];
  quarterOptions: OptionsItem<string>[];
  rentMetricOptions: OptionsItem<Metric>[];
  rentTypeOptions: OptionsItem[];
  requirementListFilterOptions: OptionsItem[];
  requirementMatchRadiusOptions: OptionsItem[];
  requirementStatusOptions: OptionsItem<RequirementStatus>[];
  requirementTypeOptions: OptionsItem[];
  saleTypeOptions: OptionsItem<string>[];
  sectorOptions: OptionsItem[];
  segmentOptions: OptionsItem[];
  showEventOptions: OptionsItem[];
  societyDisposalReportTypeOptions: OptionsItem[];
  societyRegionOptions: OptionsItem[];
  sourceOptions: OptionsItem[];
  tagOptions: OptionsItem[];
  taskRepeatOptions: OptionsItem<TaskFrequency>[];
  teamOptions: OptionsItem<number>[];
  teamSegmentOptions: OptionsItem[];
  teamStatusOptions: OptionsItem[];
  tenancyStatusOptions: OptionsItem[];
  tenureOptions: OptionsItem[];
  viewingFeedbackRadioOptions: OptionsItem<ViewingOutcome>[];
  weekDayOptions: OptionsItem<ShortWeekDay>[];
};

export const OptionsContext = createContext<UseOptionResult>({} as UseOptionResult);

export const useOptions = () => {
  return useContext(OptionsContext);
};

export const OptionsProvider: FC = ({ children }) => {
  const { constants, offices, segments, team_segments, teams, user } = useConfig();

  const allAdvisoryStatusOptions = useMemo(
    () => idArrayToNumberOptions(constants.advisoryStatusOptions),
    [constants.advisoryStatusOptions]
  );
  const allSectorOptions = useMemo(() => objectToOptions(constants.sectors), [constants.sectors]);
  const allTaskTypeOptions = useMemo(() => objectToOptions(constants.taskTypes), [constants.taskTypes]);

  const availableSectorOptions = useMemo(
    () => filterOptions(allSectorOptions, user.organisation?.settings.sectors_visible, true),
    [allSectorOptions, user.organisation?.settings.sectors_visible]
  );
  const availableTaskTypeOptions = useMemo(
    () => filterOptions(allTaskTypeOptions, user.organisation?.settings.task_types_visible, true),
    [allTaskTypeOptions, user.organisation?.settings.task_types_visible]
  );

  const result: UseOptionResult = {
    acquisitionIntelStatusOptions: useMemo(
      () => idArrayToNumberOptions(constants.acquisitionIntelStatusOptions),
      [constants.acquisitionIntelStatusOptions]
    ),
    advisoryStatusOptions: allAdvisoryStatusOptions,
    advisoryTypeOptions: useMemo(
      () => idArrayToNumberOptions(constants.advisoryTypeOptions),
      [constants.advisoryTypeOptions]
    ),
    archiveRadioOptions: useMemo(
      () => [
        { id: 1, label: t('archive') },
        { id: 0, label: t('dont_archive') },
      ],
      []
    ),
    availabilityMonthOptions: useMemo(
      () => objectToOptions(constants.availabilityMonths),
      [constants.availabilityMonths]
    ),
    availableBuildingTypeGroupOptions: useMemo(() => groupOptionsByBuildingType(constants, user), [constants, user]),
    availableMeasureOptions: useMemo(() => objectToOptions(constants.availableMeasures), [constants.availableMeasures]),
    availableSizeMetricOptions: useMemo(
      () => stringObjectToOptions<Metric>(constants.availableSizeMetrics),
      [constants.availableSizeMetrics]
    ),
    availableTaskTypeOptions: availableTaskTypeOptions,
    booleanOptions: useMemo(
      () => [
        { id: 1, label: t('yes') },
        { id: 0, label: t('no') },
      ],
      []
    ),
    booleanStringOptions: useMemo(
      () => [
        { label: t('yes'), id: '1' },
        { label: t('no'), id: '0' },
      ],
      []
    ),
    booleanSpecOptions: useMemo(
        () => [
          { label: t('yes'), id: t('yes') },
          { label: t('no'), id: t('no') },
        ],
        []
    ),
    buildingTypeOptions: useMemo(() => objectToOptions(constants.buildingTypes), [constants.buildingTypes]),
    buildStatusOptions: useMemo(() => objectToOptions(constants.buildStatusOptions), [constants.buildStatusOptions]),
    companyStatusOptions: useMemo(
      () => idArrayToNumberOptions<CompanyStatus>(constants.companyStatusOptions),
      [constants.companyStatusOptions]
    ),
    companyTypeOptions: useMemo(() => stringObjectToOptions(constants.companyTypes), [constants.companyTypes]),
    confirmedEventOptions: useMemo(
      () => [
        { id: '0', label: t('provisional_1') },
        { id: '1', label: t('confirmed') },
      ],
      []
    ),
    consentSourceOptions: useMemo(
      () => [
        { id: 1, label: t('website') },
        { id: 2, label: t('verbal') },
        { id: 3, label: t('email') },
      ],
      []
    ),
    contactStatusOptions: useMemo(
      () => idArrayToNumberOptions<ContactStatus>(constants.contactStatusOptions),
      [constants.contactStatusOptions]
    ),
    contactTitleOptions: useMemo(
      () => [
        { id: 1, label: t('mr') },
        { id: 2, label: t('mrs') },
        { id: 3, label: t('ms') },
        { id: 4, label: t('miss') },
        { id: 5, label: t('dr') },
        { id: 6, label: t('professor') },
        { id: 7, label: t('reverend') },
        { id: 8, label: t('pastor') },
        { id: 9, label: t('rabbi') },
        { id: 10, label: t('sister') },
      ],
      []
    ),
    contactTypeOptions: useMemo(() => objectToOptions(constants.contactTypes), [constants.contactTypes]),
    countryOptions: useMemo(() => objectToOptions(constants.countryOptions, true), [constants.countryOptions]),
    creatableAdvisoryStatusOptions: useMemo(
      () => filterOptions(allAdvisoryStatusOptions, constants.creatableAdvisoryStatusOptions),
      [allAdvisoryStatusOptions, constants.creatableAdvisoryStatusOptions]
    ),
    creatableTaskTypeOptions: useMemo(
      () => filterOptions(availableTaskTypeOptions, constants.creatableTaskTypes, true),
      [availableTaskTypeOptions, constants.creatableTaskTypes]
    ),
    currencyOptions: useMemo(
      () =>
        constants.localisation.currencyOptionsDropdown.map((option) => ({
          id: option.id,
          label: option.name,
        })),
      [constants.localisation.currencyOptionsDropdown]
    ),
    devPipelineLikelihoodOptions: useMemo(
      () => objectToOptions(constants.devPipelineLikelihoodOptions),
      [constants.devPipelineLikelihoodOptions]
    ),
    devPipelineFundingOptions: useMemo(
      () => objectToOptions(constants.devPipelineFundingOptions),
      [constants.devPipelineFundingOptions]
    ),
    devPipelineStatusOptions: useMemo(
      () => objectToOptions(constants.devPipelineStatusOptions),
      [constants.devPipelineStatusOptions]
    ),
    devPipelineTypeOptions: useMemo(
      () => objectToOptions(constants.devPipelineTypeOptions),
      [constants.devPipelineTypeOptions]
    ),
    diaryTaskStatusOptions: useMemo(
      () => [
        { label: t('all_statuses'), id: '' },
        { label: t('open'), id: 'open' },
        { label: t('completed'), id: 'completed' },
      ],
      []
    ),
    discountReasonOptions: useMemo(
      () => objectToOptions(constants.matchDiscountReasonOptions),
      [constants.matchDiscountReasonOptions]
    ),
    disposalConditionTypeOptions: useMemo(
      () => objectToOptions(constants.disposalConditionTypes),
      [constants.disposalConditionTypes]
    ),
    disposalFittedSpaceOptions: useMemo(
      () => objectToOptions(constants.disposalFittedSpaceOptions),
      [constants.disposalFittedSpaceOptions]
    ),
    disposalGradeOptions: useMemo(
      () => objectToOptions(constants.disposalGradeOptions),
      [constants.disposalGradeOptions]
    ),
    disposalPublishedToOptions: useMemo(
      () => objectToOptions(constants.disposalsPublishedToOptions),
      [constants.disposalsPublishedToOptions]
    ),
    disposalStatusOptions: useMemo(
      () => objectToOptions(constants.disposalStatusOptions),
      [constants.disposalStatusOptions]
    ),
    disposalTermsOfEngagementOptions: useMemo(
      () => objectToOptions(constants.disposalTermsOfEngagementOptions),
      [constants.disposalTermsOfEngagementOptions]
    ),
    epcBandOptions: useMemo(() => objectToOptions(constants.epcBandOptions), [constants.epcBandOptions]),
    eventNotificationOptions: useMemo(
      () => [
        { id: '0', label: 'No' },
        { id: '1', label: 'Yes' },
      ],
      []
    ),
    feeCurrencyOptions: useMemo(
      () =>
        mapStringRecord(constants.feeCurrencyOptions, (option) => ({
          id: option?.iso,
          label: option?.name,
        })),
      [constants.feeCurrencyOptions]
    ),
    feesAdvisoryStages: useMemo(
      () => stringObjectToNumberOptions(constants.feesAdvisoryStages),
      [constants.feesAdvisoryStages]
    ),
    feesAllStagesOptions: useMemo(
      () => stringObjectToNumberOptions(constants.feesAllStagesOptions),
      [constants.feesAllStagesOptions]
    ),
    feesBasisOptions: useMemo(() => objectToOptions(constants.feesBasisOptions), [constants.feesBasisOptions]),
    feesDisposalLeaseStagesOptions: useMemo(
      () => stringObjectToNumberOptions(constants.feesDisposalLeaseStages),
      [constants.feesDisposalLeaseStages]
    ),
    feesPaymentStatusOptions: useMemo(
      () => idArrayToNumberOptions(constants.feesPaymentStatusOptions),
      [constants.feesPaymentStatusOptions]
    ),
    feesRequirementLeaseStagesOptions: useMemo(
      () => stringObjectToNumberOptions(constants.feesRequirementLeaseStages),
      [constants.feesRequirementLeaseStages]
    ),
    feesSourceCrossSellExternalOptions: useMemo(
      () => stringObjectToOptions(constants.feesSourceCrossSellExternalOptions),
      [constants.feesSourceCrossSellExternalOptions]
    ),
    feesSourceCrossSellInternalOptions: useMemo(
      () => stringObjectToOptions(constants.feesSourceCrossSellInternalOptions),
      [constants.feesSourceCrossSellInternalOptions]
    ),
    feesSourceOptions: useMemo(
      () => idArrayToNumberOptions(constants.feesSourceOptions),
      [constants.feesSourceOptions]
    ),
    feesSourceOwnActivityOptions: useMemo(
      () => stringObjectToOptions(constants.feesSourceOwnActivityOptions),
      [constants.feesSourceOwnActivityOptions]
    ),
    feesStatusOptions: useMemo(
      () => idArrayToNumberOptions(constants.feesStatusOptions),
      [constants.feesStatusOptions]
    ),
    feesTypeOptions: useMemo(() => objectToOptions(constants.feesTypeOptions), [constants.feesTypeOptions]),
    filterableRentMetricOptions: useMemo(
      () => stringObjectToOptions<Metric>(constants.rentMetricsFilterable),
      [constants.rentMetricsFilterable]
    ),
    fitoutConceptOptions: useMemo(
      () => objectToOptions(constants.fitoutConceptOptions),
      [constants.fitoutConceptOptions]
    ),
    fitoutConditionOptions: useMemo(
      () => objectToOptions(constants.fitoutConditionOptions),
      [constants.fitoutConditionOptions]
    ),
    floorUnitAvailabilityColumnOptions: useMemo(
      () => objectToOptions(constants.floorUnitAvailabilityColumns),
      [constants.floorUnitAvailabilityColumns]
    ),
    includeInstructionOptions: useMemo(
      () => [
        { id: '1', label: t('include_our_instructions') },
        { id: '0', label: t('include_market_intel') },
      ],
      []
    ),
    investmentCurrencyOptions: useMemo(
      () =>
        mapStringRecord(constants.investmentCurrencyOptions, (option) => ({
          id: option?.iso,
          label: option?.name,
        })),
      [constants.investmentCurrencyOptions]
    ),
    investmentCurrencySymbolOptions: useMemo(
      () =>
        mapStringRecord(constants.investmentCurrencyOptions, (option) => ({
          id: option?.iso,
          label: option?.symbol,
        })),
      [constants.investmentCurrencyOptions]
    ),
    investmentGradeOptions: useMemo(
      () => objectToOptions(constants.investmentGradeOptions),
      [constants.investmentGradeOptions]
    ),
    investmentMetricOptions: useMemo(
      () => stringObjectToOptions(constants.investmentMetrics),
      [constants.investmentMetrics]
    ),
    investmentProfileOptions: useMemo(
      () => objectToOptions(constants.investmentProfileOptions),
      [constants.investmentProfileOptions]
    ),
    investmentStrategyOptions: useMemo(
      () => objectToOptions(constants.investmentStrategyOptions),
      [constants.investmentStrategyOptions]
    ),
    investmentStructureOptions: useMemo(
      () => objectToOptions(constants.investmentStructureOptions),
      [constants.investmentStructureOptions]
    ),
    investorRankingOptions: useMemo(
      () => objectToOptions(constants.investorRankingOptions),
      [constants.investorRankingOptions]
    ),
    investorRegionOptions: useMemo(
      () => objectToOptions(constants.investorRegionOptions),
      [constants.investorRegionOptions]
    ),
    investorTypeOptions: useMemo(() => objectToOptions(constants.investorTypeOptions), [constants.investorTypeOptions]),
    jointExclusiveInstructionOptions: useMemo(
      () => [
        { id: 'exclusive_instructions', label: t('exclusive_instructions') },
        { id: 'joint_instructions', label: t('joint_instructions') },
      ],
      []
    ),
    kycCompletedOptions: useMemo(() => objectToOptions(constants.kycCompletedOptions), [constants.kycCompletedOptions]),
    leaseExpiryReasonOptions: useMemo(
      () => objectToOptions(constants.leaseExpiryReasonOptions),
      [constants.leaseExpiryReasonOptions]
    ),
    leaseTypeOptions: useMemo(() => objectToOptions(constants.leaseTypes), [constants.leaseTypes]),
    marketingListOptions: useMemo(
      () => constants.marketingLists.map((option) => ({ id: option.id, label: option.name })),
      [constants.marketingLists]
    ),
    matchDisposalsSortOptions: useMemo(
      () => [
        { id: 'published_at-desc', label: t('published_at') },
        { id: 'updated_at-desc', label: t('updated_at') },
        { id: 'size_to-desc', label: t('size_descending') },
        { id: 'size_from-asc', label: t('size_ascending') },
      ],
      []
    ),
    metricOptions: useMemo(() => stringObjectToOptions<Metric>(constants.genericMetrics), [constants.genericMetrics]),
    nationalityOptions: useMemo(() => objectToOptions(constants.nationalityOptions), [constants.nationalityOptions]),
    officesOptions: useMemo(() => idArrayToNumberOptions(offices), [offices]),
    openEventOptions: useMemo(
      () => [
        { id: '1', label: 'Yes' },
        { id: '0', label: 'No, Invite only' },
      ],
      []
    ),
    ownershipTypeOptions: useMemo(
      () => stringObjectToNumberOptions(constants.availableSaleTypeOptions),
      [constants.availableSaleTypeOptions]
    ),
    planningStatusOptions: useMemo(
      () => objectToOptions(constants.planningStatusOptions),
      [constants.planningStatusOptions]
    ),
    quarterOptions: useMemo(
      () => [
        { id: 'Q1', label: 'Q1' },
        { id: 'Q2', label: 'Q2' },
        { id: 'Q3', label: 'Q3' },
        { id: 'Q4', label: 'Q4' },
      ],
      []
    ),
    rentMetricOptions: useMemo(() => stringObjectToOptions<Metric>(constants.rentMetrics), [constants.rentMetrics]),
    rentTypeOptions: useMemo(() => objectToOptions(constants.rentTypes), [constants.rentTypes]),
    requirementListFilterOptions: useMemo(() => {
      return (constants.requirementListOptions || [])
        .filter((option) => option.can_filter)
        .map((option) => ({
          id: option.id,
          label: option.name,
        }));
    }, [constants.requirementListOptions]),
    requirementMatchRadiusOptions: useMemo(
      () =>
        [0, 0.25, 0.5, 1, 2, 5, 10, 25].map((radius) => ({
          id: radius.toString(),
          label: t('search_radius_xradiusx_miles', { radius }),
        })),
      []
    ),
    requirementStatusOptions: useMemo(
      () => idArrayToNumberOptions(constants.requirementStatusOptions),
      [constants.requirementStatusOptions]
    ),
    requirementTypeOptions: useMemo(
      () => [
        { id: 'direct_enquiries', label: t('direct_enquiries') },
        { id: 'agent_led', label: t('agentled') },
        { id: 'our_acquisitions', label: t('our_acquisitions') },
        { id: 'availability_schedules', label: t('availability_schedules') },
      ],
      []
    ),
    saleTypeOptions: useMemo(
      () => [
        { label: t('investment'), id: '1' },
        { label: t('occupational'), id: '0' },
      ],
      []
    ),
    sectorOptions: availableSectorOptions,
    segmentOptions: useMemo(
      () =>
        mapStringRecord(segments, (option) => ({
          id: `${option.id}`,
          label: option.name,
        })),
      [segments]
    ),
    showEventOptions: useMemo(
      () => [
        { label: t('my_events'), id: 'mine' },
        { label: t('team_events'), id: 'teams' },
        { label: t('all_events'), id: '' },
      ],
      []
    ),
    societyDisposalReportTypeOptions: useMemo(
      () => [
        { id: 1, label: 'Competition Schedule' },
        { id: 2, label: 'Feasibility Study' },
        { id: 3, label: 'Market Research' },
      ],
      []
    ),
    societyRegionOptions: useMemo(() => objectToNumberOptions(constants.societyRegions), [constants.societyRegions]),
    sourceOptions: useMemo(
      () => sourceOptionsSelectOptions(constants.localisation.sourceOptions),
      [constants.localisation.sourceOptions]
    ),
    tagOptions: useMemo(() => objectToOptions(constants.tags), [constants.tags]),
    taskRepeatOptions: useMemo(
      () => [
        { label: t('doesnt_repeat'), id: TaskFrequency.None },
        { label: t('daily'), id: TaskFrequency.Daily },
        { label: t('weekly'), id: TaskFrequency.Weekly },
        { label: t('monthly'), id: TaskFrequency.Monthly },
        { label: t('annually'), id: TaskFrequency.Yearly },
      ],
      []
    ),
    teamOptions: useMemo(() => idArrayToNumberOptions(teams), [teams]),
    teamSegmentOptions: useMemo(
      () =>
        mapStringRecord(team_segments, (option) => ({
          id: `${option.id}`,
          label: option.name,
        })),
      [team_segments]
    ),
    teamStatusOptions: useMemo(
      () => [
        { label: t('active'), id: '0' },
        { label: t('scraper'), id: '1' },
        { label: t('scraper_with_meta'), id: '2' },
        { label: t('inactive'), id: '3' },
        { label: t('suspended'), id: '10' },
        { label: t('deleted'), id: '100' },
      ],
      []
    ),
    tenancyStatusOptions: useMemo(
      () => objectToOptions(constants.targetTenancyDetails),
      [constants.targetTenancyDetails]
    ),
    tenureOptions: useMemo(
      () => [
        { id: 'to_let', label: t('to_let') },
        { id: 'for_sale', label: t('occupational_sale') },
        { id: 'investment_sale', label: t('investment_sale') },
      ],
      []
    ),
    viewingFeedbackRadioOptions: useMemo(
      () => [
        { id: ViewingOutcome.Positive, label: t('positive_feedback') },
        { id: ViewingOutcome.Neutral, label: t('neutral_feedback') },
        { id: ViewingOutcome.Negative, label: t('negative_feedback') },
      ],
      []
    ),
    weekDayOptions: useMemo(
      () => [
        { label: t('monday'), id: ShortWeekDay.Monday },
        { label: t('tuesday'), id: ShortWeekDay.Tuesday },
        { label: t('wednesday'), id: ShortWeekDay.Wednesday },
        { label: t('thursday'), id: ShortWeekDay.Thursday },
        { label: t('friday'), id: ShortWeekDay.Friday },
        { label: t('saturday'), id: ShortWeekDay.Saturday },
        { label: t('sunday'), id: ShortWeekDay.Sunday },
      ],
      []
    ),
  };
  return <OptionsContext.Provider value={result}>{children}</OptionsContext.Provider>;
};
